import { Box } from '@mui/material';
import Carousel from 'react-multi-carousel';

import ArrowCircleButton from 'src/components/atoms/arrowCircleButton';
import Card from 'src/components/molecules/courseCard/card';
import { useCoursesEnrollment, useWindowSize } from 'src/helpers/use';
import { ICourse } from 'src/interfaces';
import IBundle from 'src/interfaces/bundle';

type Props = {
  courses: ICourse[];
  maxItems?: number;
  bundles?: ICourse[];
};

const CardCarousel = ({ courses, maxItems = 4, bundles = [] }: Props) => {
  const { width } = useWindowSize();
  const { coursesData } = useCoursesEnrollment(bundles.concat(courses) as ICourse[]);

  const CARD_WIDTH = 300;
  const cardCount = Math.floor(width! / CARD_WIDTH);
  const gutter = (width! % CARD_WIDTH) / cardCount;

  const getMaxItems = (countItems: number) => (countItems > maxItems ? maxItems : countItems);
  const responsive = {
    largeDesktop: {
      breakpoint: { max: 6000, min: 2000 },
      items: getMaxItems(6),
      slidesToSlide: getMaxItems(6),
    },
    mediumDesktop: {
      breakpoint: { max: 1999, min: 1701 },
      items: getMaxItems(5),
      slidesToSlide: getMaxItems(5),
    },
    desktop: {
      breakpoint: { max: 1700, min: 1200 },
      items: getMaxItems(4),
      slidesToSlide: getMaxItems(4),
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: getMaxItems(cardCount),
      slidesToSlide: getMaxItems(cardCount),
      partialVisibilityGutter: coursesData!.length <= cardCount ? 0 : gutter,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: getMaxItems(cardCount),
      slidesToSlide: getMaxItems(cardCount),
      partialVisibilityGutter: coursesData!.length <= cardCount ? 0 : gutter,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      partialVisible
      draggable={width! < 1200}
      swipeable={width! < 1200}
      containerClass="carousel-container"
      itemClass={`carousel-item ${coursesData!.length <= cardCount && 'width'}`}
      ssr
      deviceType="desktop"
      customRightArrow={<ArrowCircleButton direction="right" />}
      customLeftArrow={<ArrowCircleButton direction="left" />}
      removeArrowOnDeviceType={['tablet', 'mobile']}
    >
      {coursesData ? coursesData?.map((course) => <Card key={course.id} course={course} isVertical />) : <Box />}
    </Carousel>
  );
};

export default CardCarousel;
