import { Box, Button, Typography, Stack } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useInView } from 'react-intersection-observer';
import NextLink from 'src/components/atoms/link';
import CardCarousel from 'src/components/molecules/courseCard/section/cardCarousel';
import { useMediaQueryDevice } from 'src/helpers/use';
import { ISection } from 'src/interfaces/course';

import CourseCardSectionStyle from './style';

import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';
import { SkeletonCourseCard } from '@components/atoms/skeleton';

type CourseCardSectionProps = {
  section: ISection;
  maxItems?: number;
  isDisplayShowAll?: boolean;
};

const CourseCardSection = ({ section, maxItems = 4, isDisplayShowAll = false }: CourseCardSectionProps) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { t } = useTranslation('common');
  const { matchesMobile, matchesIpad, matchesDesktop, matchesMediumDesktop } = useMediaQueryDevice();
  const skeletonAmount = (() => {
    if (matchesMobile) return 1;
    if (matchesIpad) return 2;
    if (matchesDesktop) return 4;
    if (matchesMediumDesktop) return 5;
    return 6;
  })();
  return (
    <CourseCardSectionStyle key={section.id} ref={ref}>
      <Box sx={{ my: 1.5, mx: 1 }} display="flex" alignItems="baseline" justifyContent="space-between">
        <Typography className="title" sx={{ maxWidth: '70%', lineHeight: 1 }} variant="h1">
          {section.name}
        </Typography>
        {isDisplayShowAll && (
          <NextLink href={`/sections/${section.url_name}`}>
            <Button variant="text" className="button-link">
              {t('home.section.see_all')} <FontAwesomeIcon icon="fa-regular fa-chevron-right" />
            </Button>
          </NextLink>
        )}
      </Box>
      {inView && <CardCarousel key={section.id} courses={section.courses} maxItems={maxItems} />}
      {!inView && (
        <Box mx="0.5rem">
          <Stack direction="row" spacing={{ lg: 2, md: 1.5 }}>
            {[...Array(skeletonAmount)].map((_, index) => (
              <SkeletonCourseCard key={index} />
            ))}
          </Stack>
        </Box>
      )}
    </CourseCardSectionStyle>
  );
};

export default CourseCardSection;
