import styled from '@emotion/styled';

const SectionsStyle = styled.div`
  & > :first-child {
    margin-top: 2rem;
  }

  .course-card-section-empty {
    height: 440px;
    margin: 4rem 0;

    ${(props: any) => props.theme.breakpoints.down('md')} {
      height: 438px;
    }
  }

  .tuxsa-section-empty {
    height: 160px;
  }

  .popular-category-section-empty {
    height: 320px;
  }

  .campaign-section-empty {
    height: 440px;

    ${(props: any) => props.theme.breakpoints.down('md')} {
      height: 438px;
    }
  }
`;

export default SectionsStyle;
