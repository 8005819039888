import { Card, CardContent, Skeleton } from '@mui/material';

export const SkeletonCourseCard = () => (
  <Card sx={{ width: 272, height: 370, boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)', borderRadius: '10px' }}>
    <Skeleton sx={{ height: 160 }} animation="wave" variant="rectangular" />
    <Skeleton animation="wave" variant="circular" width={40} height={40} sx={{ ml: 2, mt: 2 }} />
    <CardContent>
      <>
        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={30} width="50%" />
        <Skeleton animation="wave" height={40} width="50%" sx={{ mt: 2 }} />
      </>
    </CardContent>
  </Card>
);
