import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import ArrowCircleButtonStyle from './style';

import Button from 'src/components/atoms/button';

type ArrowCircleButtonProps = {
  direction: 'left' | 'right';
  onClick?: () => void;
  isFixTop?: boolean;
  arrowSingleRow?: boolean;
  className?: string;
};

const ArrowCircleButton = ({ direction, onClick, isFixTop, className, arrowSingleRow }: ArrowCircleButtonProps) => (
  <ArrowCircleButtonStyle
    isFixTop={isFixTop}
    direction={direction}
    className={className}
    arrowSingleRow={arrowSingleRow}
  >
    <Button className="arrow-button" onClick={onClick} aria-label="circle-button">
      {direction === 'left' ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
    </Button>
  </ArrowCircleButtonStyle>
);

export default ArrowCircleButton;
