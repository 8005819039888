import { withTheme } from '@mui/styles';
import styled from 'styled-components';
import 'react-multi-carousel/lib/styles.css';

const CourseCardSectionStyle = withTheme(styled.div`
  padding: 0 1rem;

  position: relative;
  .title {
    flex-grow: 2;
  }
  .carousel-item {
    flex: 0 0 !important;
  }
  .button-link {
    margin-right: -8px;
    color: ${(props: any) => props.theme.palette.primary.main};
    font-family: 'Heavent';
    font-size: 18px;
    font-weight: bold;
    i {
      display: flex;
      margin-left: 8px;
      font-size: 16px;
    }
  }
  @media (min-width: 2000px) {
    max-width: 1762px !important;
  }
  @media (max-width: 1999px) and (min-width: 1701px) {
    max-width: 1472px !important;
  }

  ${(props: any) => props.theme.breakpoints.up('lg')} {
    max-width: 1182px;
    margin: 4rem auto;
  }

  ${(props: any) => props.theme.breakpoints.down('lg')} {
    margin: 4rem 0;
  }
  ${(props: any) => props.theme.breakpoints.down('md')} {
    margin: 3rem 0.5rem;
    padding: 0px;
    .title {
      font-size: 24px;
    }
  }
  .react-multi-carousel-list {
    position: unset;
  }
`);

export default CourseCardSectionStyle;
