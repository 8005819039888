import styled from '@emotion/styled';

const ArrowCircleButtonStyle = styled.div<{
  direction: string;
  isFixTop?: boolean;
  arrowSingleRow?: boolean;
}>`
  button > svg {
    font-size: 44px;
  }
  ${(props: any) => props.direction === 'right' && 'right: -6px;'}
  ${(props: any) => props.direction === 'left' && 'left: -6px;'}
  ${(props: any) => props.isFixTop && 'top: calc(50% - 15px);'}
  ${(props: any) => props.arrowSingleRow && 'bottom: 16px;'}
  position: absolute;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  z-index: 1;
  background-color: white;

  .arrow-button {
    min-width: 60px;
    min-height: 60px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    z-index: 1;
    color: ${(props: any) => props.theme.palette.primary.dark};
  }

  ${(props: any) => props.theme.breakpoints.down('lg')} {
    ${(props: any) => props.direction === 'right' && 'right: 4px;'}
    ${(props: any) => props.direction === 'left' && 'left: 4px;'}
    .arrow-button {
        min-width: 40px;
        min-height: 40px;
    }
    svg {
        font-size: 24px;
    }
  }
  ${(props: any) => props.theme.breakpoints.down('md')} {
    ${(props: any) => props.direction === 'right' && 'right: -8px;'}
    ${(props: any) => props.direction === 'left' && 'left: -8px;'}
  }
`;

export default ArrowCircleButtonStyle;
